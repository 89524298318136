<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel :header="name">
          <BasicFormToolbar v-if="!view" @new="newEntity" @save="save" @list="openTable" :actions="actions"
           :uploadURL="uploadURL"
          @uploadError="onUploadError"
          @uploaded="uploaded"
          @beforeUpload="loading = true" />          
          <div class="grid formgrid p-fluid">
            <div class="col-12">
                <Fieldset :toggleable="true" legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <div :class="getClass(input)" v-for="input in inputs" :key="input.label">
                      <FormDropdown :wrapperClass="'field'"  @change="onChangeBranch" :label="'Sucursal'" v-if="input.type == 'branch'" v-model="internalEntity.id_branch" :options="branches" :optionLabel="'branch_name'" :optionValue="'id_branch'"/>
                      <FormInputText :wrapperClass="'field'" :valid="internalValidate.validations[input.model]" :label="input.label" v-else-if="input.type == 'text'" v-model="internalEntity[input.model]"/>
                      <FormInputNumber :wrapperClass="'field'"  :valid="internalValidate.validations[input.model]" :min="0" :label="input.label" v-else-if="input.type == 'number'" v-model="internalEntity[input.model]"/>
                      <FormDropdown :wrapperClass="'field'"  :valid="internalValidate.validations[input.model]" :label="input.label" v-else-if="input.type == 'dropdown'" v-model="internalEntity[input.model]" :options="input.options" :optionLabel="input.options_label" :optionValue="input.options_value"
                      :clear="true"  @clear="inputEmitter(input, 'clear')" />
                      <FormCalendar :wrapperClass="'field'"  :valid="internalValidate.validations[input.model]" :label="input.label" v-else-if="input.type == 'calendar'" v-model="internalEntity[input.model]" />
                      <FormInputSwitch :wrapperClass="'field'"  :valid="internalValidate.validations[input.model]" :label="input.label" v-else-if="input.type == 'switch'" v-model="internalEntity[input.model]" />
                      <div v-else class="field">
                        <label >{{input.label}}</label>
                        <component v-model="internalEntity[input.model]" v-if="input.props" :is="input.type" v-bind="input.props"></component>
                      </div>
                    </div>
                  </div>
                </Fieldset>
                <br>
            </div>
            <div v-if="!modal" class="col-12">
              <Fieldset :toggleable="true" legend="Resultados">
                <BasicDatatable :exportFilename="name + '_export'" :rows="entities" :headers="headers" :rowaction="true" :rowdelete="true" :rowedit="true" 
                @edited="edit" @deleted="confirmDelete"/>
                <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="deleteEntity" />
              </Fieldset>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
//* Basic Components
import BasicDatatable from "../general/BasicDatatable.vue";
import BasicFormToolbar from "../general/BasicFormToolbar.vue";
import FormCalendar from "../general/FormCalendar.vue";
import FormDropdown from "../general/FormDropdown.vue";
import FormInputNumber from "../general/FormInputNumber.vue";
import FormInputText from "../general/FormInputText.vue";
import FormInputSwitch from "../general/FormInputSwitch.vue";

import DeleteDialog from "../general/DeleteDialog.vue";
import Loader from "../general/Loader.vue";
import Session from "../../mixins/sessionMixin";

import {
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../utilities/General";
import { ERPUser } from '../../models/seguridad/ERPUser';

/* eslint-disable vue/no-unused-components */
export default {
  mixins: [Session],
  emits: ["editing", "delete", "refresh", "change-brach", "new", "validate-change","update","save", "list"],
  props: {
    modal: null,
    id: null,
    view: null,
    uploadURL: null,
    inputs: {
        type: [Array, null],
        default: () => []
    }, 
    actions: {
        type: [Array, null],
        default: () => ['new','save','list', 'import']
    },
    rules: {
        type: [Array, null],
        default: () => []
    },
    headers: {
        type: [Array, null],
        default: () => []
    },
    validate: {
        type: [Object, null],
        default: () => {}
    },
    name: {
        type: String,
        default: ""
    },
    modelValue: {
        required: true
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    modelValue(newValue) {
      this.internalEntity = newValue;
    },
  },
  data() {
    return {
      open: false,
      branches: [],
      internalEntity: this.modelValue,
      internalValidate: this.validate,
      entities: [],
      deleteDialog: false,
      loading: false,
    };
  },
  components: { FormInputSwitch, DeleteDialog, Loader, FormCalendar, FormDropdown, FormInputNumber , FormInputText, BasicFormToolbar, BasicDatatable },
  async mounted() {
    await this.refresh();
  },   
  methods: {
    async openTable(){
      this.entities = await this.internalEntity.all();
      this.open = true;
    },
    inputEmitter(input, event, data) {
      this.$emit(input.model + "-" + event, {
        input: input,
        data: data
      });
    },
    onChangeBranch(payload) {
      this.$emit('change-brach', payload);
    },
    uploaded() {
      this.$toast.add({
          severity: "success",
          summary: "Importar",
          detail: "Registros importados con exito",
          life: 3000,
        });
      this.refresh();
    },
    onUploadError(message) {
        this.loading = false;
        console.log("error1");
        this.$toast.add(new ErrorToast(message));
    },
    async confirmDelete(entity) {
      this.internalEntity = fillObject(this.internalEntity, entity);
      this.deleteDialog = true;
    },
    getClass(input) {
      return input.cols ? 'col-12 md:col-4 xl:col-' + input.cols : 'col';
    },
    newEntity() {
      this.$emit('new');
      this.internalValidate.clear();
    },
    async save() {
      console.log("Si entro al save")
      try {
        this.loading = true;
        this.internalValidate = validate(this.internalEntity, this.rules);
        this.$emit('validate-change', this.internalValidate);
        if (!this.internalValidate.valid) 
          throw "Favor de validar los campos";
        if (this.internalEntity.id && this.internalEntity.id > 0) {
          let entity = await this.internalEntity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          if (entity.id_branch == this.session.branch) {
            let index = this.entities.findIndex((x) => x.id == this.internalEntity.id);
            this.entities[index] = entity;
          }
        } else {
          console.log("HOLAa");
          console.log(JSON.stringify(this.internalEntity));
          let entity = await this.internalEntity.save();
          this.$emit('save', entity);
          if (entity.id_branch == this.session.branch) {
            this.entities.push(entity);
          }
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.newEntity();
        this.entities=null;
        if (this.open == true){
          this.entities = await this.internalEntity.all();
        }
      } catch (error) {
        console.log("error2");
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.internalEntity = fillObject(this.internalEntity, entity);
      this.$emit("editing", this.internalEntity)
    },
    async deleteEntity() {
      this.loading = true;
      try {
        await this.internalEntity.delete();
        this.entities = this.entities.filter((val) => val.id !== this.internalEntity.id);
        this.deleteDialog = false;
        this.$emit('delete');
        this.newEntity();
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        console.log("error3");
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        if (!this.modal) 
            //this.entities = await this.internalEntity.all();
        if (this.id) {
          this.internalEntity.id = this.id;
          let entity = await this.internalEntity.retrieve();
          this.internalEntity = fillObject(this.internalEntity, entity);
        }
        if (this.inputs.find(x => x.type == "branch")) {
          let user = new ERPUser();
          user.username = this.session.user;
          this.branches = await user.access();
          this.branches = this.branches.filter(x => x.id_company == this.session.company);
          this.$emit('refresh');
        }
      } catch (error) {
        console.log("error4");
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
